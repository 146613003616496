import React from 'react';
import AppointmentBooking from './components/AppointmentBooking';

function App() {
  return (
    <div className="App">
      <h1>Миний цаг захиалгын апп</h1>
      <AppointmentBooking />
    </div>
  );
}

export default App;
